import React, { useRef } from 'react'
import SunEditor from "suneditor-react";
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
    blockquote,
    math,
    video
} from "suneditor/src/plugins";

function SunCustomEditor({ value, onChange, id }) {

    const editor = useRef();

    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };


    return (
        <SunEditor
            id={id}
            getSunEditorInstance={getSunEditorInstance}
            setContents={value}
            onChange={onChange}
            setOptions={{
                defaultStyle: "font-family:Verdana,Arial,Helvetica,sans-serif, color:black",
                showPathLabel: false,
                minHeight: "150px",
                maxHeight: "250px",
                placeholder: "Enter your text here!!!",
                plugins: [
                    blockquote,
                    align,
                    fontColor,
                    fontSize,
                    formatBlock,
                    hiliteColor,
                    horizontalRule,
                    lineHeight,
                    list,
                    paragraphStyle,
                    table,
                    template,
                    textStyle,
                    image,
                    link,
                ],
                buttonList: [
                    ["undo", "redo"],
                    ["fontSize", "formatBlock"],
                    ["paragraphStyle"],
                    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                    ["fontColor", "hiliteColor"],
                    ["removeFormat"],
                    ["outdent", "indent"],
                    ["align", "list", "lineHeight"],
                    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'save']
                ],
                formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                font: ["Roboto", "Arial", "Calibri", "Comic Sans", "Courier",
                    "Garamond", "Georgia", "Impact", "Lucida Console", "Palatino Linotype",
                    "Segoe UI", "Tahoma", "Times New Roman", "Trebuchet MS"]
            }}

        />
    )
}

export default SunCustomEditor