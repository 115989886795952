import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

const InputControl = ({ label, type, value, name, onChange, ...rest }) => {
  return (
    <>
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Input
          type={type || "text"}
          value={value}
          name={name}
          onChange={onChange}
          {...rest}
        />
      </FormControl>
    </>
  );
};

export default InputControl;
