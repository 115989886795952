import axios from "axios";

export const createMasterProject = async (payload) => {
  try {
    const res = await axios.post(`create/product-master`, payload);
    return res;
  } catch (error) {
    return error;
  }
};
export const getAllProject = async () => {
  try {
    const res = await axios.get(`list/product-master`);
    return res;
  } catch (error) {
    return error;
  }
};
export const getAllProjectDetail = async () => {
  try {
    const res = await axios.get(`list/product-detail`);
    return res;
  } catch (error) {
    return error;
  }
};
export const deleteProject = async (id) => {
  try {
    const res = await axios.delete(`delete/product-master/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
export const createDetailProduct = async (payload) => {
  try {
    const res = await axios.post(`create/product-detail`, payload);
    return res;
  } catch (error) {
    return error;
  }
};
export const getMasterProductById = async (id) => {
  try {
    const res = await axios.get(`list/product-master/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
export const updateMaster = async (id, payload) => {
  try {
    const res = await axios.put(`update/product-master/${id}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};
export const deleteDetail = async (id) => {
  try {
    const res = await axios.delete(`delete/product-detail/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const updateDetail = async (id, payload) => {
  try {
    const res = await axios.put(`update/product-detail/${id}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const getProductDetailById = async (id) => {
  try {
    const res = await axios.get(`list/product-detail/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
