/* eslint-disable no-unused-vars */
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteCaseStudyData,
  fetchAllCaseStudy,
} from "../../services/CaseStudyService";
import moment from "moment";

const CaseStudy = () => {
  const navigate = useNavigate();
  const [caseStudyDataList, setCaseStudyDataList] = useState([]);

  const fetchAllCaseStudyHandler = async () => {
    const res = await fetchAllCaseStudy();
    setCaseStudyDataList(res.data.data || []);
  };

  useEffect(() => {
    fetchAllCaseStudyHandler();
  }, []);

  const deleteCaseStudyDataHandler = async (id) => {
    const res = await deleteCaseStudyData(id);
    fetchAllCaseStudyHandler();
  };

  return (
    <>
      <Box>
        <Box
          boxShadow="base"
          p={5}
          background="#fff"
          display="flex"
          justifyContent="space-between"
          alignContent="center"
        >
          <Text fontSize="22px"> Case Study - List</Text>
          <Button
            onClick={() => navigate("/case-study/create")}
            leftIcon={<AddIcon />}
            colorScheme="blue"
            variant="solid"
          >
            Add New Case Study
          </Button>
        </Box>
        <Box p={5}>
          <TableContainer background="#fff" boxShadow="base" p={5}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Sr. No.</Th>
                  <Th>Name</Th>
                  <Th>Heading</Th>
                  <Th>Date</Th>
                  <Th textAlign="right"> Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {caseStudyDataList.map((page, index) => (
                  <Tr>
                    <Td width="5%">{index + 1}</Td>

                    <Td>{page.name}</Td>
                    <Td>{page.heading}</Td>
                    <Td>{moment(page.date).format('DD-MM-YYYY')}</Td>

                    <Td textAlign="right">
                      <HStack justifyContent={"flex-end"}>
                        <IconButton
                          aria-label="Search database"
                          onClick={() =>
                            navigate(`/case-study/edit/${page.id}`)
                          }
                          icon={<EditIcon />}
                        />
                        <IconButton
                          aria-label="Search database"
                          onClick={() => deleteCaseStudyDataHandler(page.id)}
                          icon={<DeleteIcon />}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box >
    </>
  );
};

export default CaseStudy;
