import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomToolbar from "./CustomToolbar";

const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "header",
  "blockquote",
  "code-block",
  "indent",
  "list",
  "direction",
  "align",
  "link",
  "image",
  "video",
  "formula",
];

const Editor = ({ value, onChange, id }) => {
  return (
    <>
      <CustomToolbar id={id} />

      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={{
          toolbar: {
            container: `#${id}`,
          },
        }}
        formats={formats}
        style={{ height: 200 }}
      />
    </>
  );
};

export default Editor;
