import axios from "axios";

export const createBanner = async (payload) => {
  try {
    const res = await axios.post(`create/banner`, payload);
    return res;
  } catch (error) {
    return error;
  }
};
export const updateBanner = async (id, payload) => {
  try {
    const res = await axios.put(`update/banner/${id}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const fetchBanner = async () => {
  try {
    const res = await axios.get(`list/banner`);
    return res;
  } catch (error) {
    return error;
  }
};
export const fetchBannerById = async (id) => {
  try {
    const res = await axios.get(`list/banner/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
export const deleteBanner = async (id) => {
  try {
    const res = await axios.delete(`delete/banner/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
