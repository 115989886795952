import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deletePage, getAllPage } from "../../services/PageService";
import moment from "moment";

const AddPage = () => {
  const navigate = useNavigate();
  const [pageContenList, setPageContentList] = useState([]);
  const toast = useToast();
  const getPageData = async () => {
    const res = await getAllPage();
    console.log(res, "res");
    const resData = res.data;
    if (resData.status) {
      setPageContentList(resData.data);
    }
    // const pageData = localStorage.getItem("pageData");
    // if (pageData) {
    //   const pageDataObj = JSON.parse(pageData);
    //   setPageContentList(pageDataObj);
    // }
  };
  useEffect(() => {
    getPageData();
  }, []);

  const deletePageHandler = async (id) => {
    const res = await deletePage(id);
    // const localPageData = localStorage.getItem("pageData");
    // if (localPageData) {
    //   const localPageDataObj = JSON.parse(localPageData);
    //   const newPageData = localPageDataObj.filter(
    //     (page) => page.seoURL !== seoURL
    //   );
    //   localStorage.setItem("pageData", JSON.stringify(newPageData));
    if (res.status === 200) {
      toast({
        title: "Page Deleted.",
        description: res?.data?.message || "something went wrong",
        status: "success",
        duration: 3000,
        isClosable: true,
      }); getPageData();
    }
    else {
      toast({
        title: "Error.",
        description: res?.response?.data?.message || "something went wrong",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

  };

  return (
    <>
      <Box>
        <Box
          boxShadow="base"
          p={5}
          background="#fff"
          display="flex"
          justifyContent="space-between"
          alignContent="center"
        >
          <Text fontSize="22px">Research & Publication - List</Text>
          <Button
            onClick={() => navigate("/page/create")}
            leftIcon={<AddIcon />}
            colorScheme="blue"
            variant="solid"
          >
            Add New Page
          </Button>
        </Box>
        <Box p={5}>
          <TableContainer background="#fff" boxShadow="base" p={5}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Sr. No.</Th>
                  <Th>Image</Th>
                  <Th> Header</Th>
                  <Th> Title</Th>
                  <Th> Sub-Title</Th>
                  <Th> Posted On</Th>
                  <Th textAlign={"right"}> Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {pageContenList.map((page, index) => (
                  <Tr>
                    <Td>{index + 1}</Td>
                    <Td>
                      <Image height="65px" src={page.imageFile} />
                    </Td>
                    <Td>
                      <a target="_blank" href={`/static/${page.seoURL}`} rel="noreferrer">
                        {page.title}
                      </a>
                    </Td>
                    <Td>{page.title}</Td>
                    <Td>{page.subTitle}</Td>
                    <Td>{moment(page.createdAt).format('DD-MM-YYYY')}</Td>
                    <Td>
                      <HStack justifyContent={"flex-end"}>
                        <IconButton
                          aria-label="Search database"
                          onClick={() =>
                            navigate(`/page/edit/${page.seoURL}/${page.id}`)
                          }
                          icon={<EditIcon />}
                        />
                        <IconButton
                          aria-label="Search database"
                          onClick={() => deletePageHandler(page.id)}
                          icon={<DeleteIcon />}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default AddPage;
