import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./PrivateRoute";
import Login from "../pages/Auth";
import Dashboard from "../pages/Dashboard";
import AddPage from "../pages/AddPage";
import CreateForm from "../pages/AddPage/CreatePage";
import StaticPage from "../pages/StaticPage";
import Product from "../pages/Product";
import MasterProduct from "../pages/Product/Master";
import DetailsProduct from "../pages/Product/Details";
import CreateMaster from "../pages/Product/CreateMaster";
import CreateDetails from "../pages/Product/CreateDetails";
import Blog from "../pages/Blog";
import CreateBlog from "../pages/Blog/CreateBlog";
import BlogRenderPage from "../pages/BlogRenderPage";
import ProductRenderPage from "../pages/ProductRenderPage";
import CreateFAQs from "../pages/FAQs/CreateFAQs";
import EditFaq from "../pages/FAQs/EditFaq";
import CreateHeading from "../pages/FAQs/CreateHeading";
import Headings from "../pages/FAQs/Headings";
import ManageContactUs from "../pages/ManageContactUs";
import MangeContactForm from "../pages/ManageContactUs/Form";
import CaseStudy from "../pages/CaseStudy";
import AddCaseStudy from "../pages/CaseStudy/AddCaseStudy";
import Banner from "../pages/Banner";
import AddBanner from "../pages/Banner/AddBanner";
import FAQHeadings from "../pages/FAQs/Headings";
import Sites from "../pages/Sites";
import CreateSite from "../pages/Sites/CreateSite";

const routeLinks = [
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/page/add",
    element: <AddPage />,
  },
  {
    path: "/page/create",
    element: <CreateForm />,
  },
  {
    path: "/page/edit/:seoURL/:id",
    element: <CreateForm />,
  },
  {
    path: "/static/:url",
    element: <StaticPage />,
  },
  {
    path: "/blog/:url",
    element: <BlogRenderPage />,
  },
  {
    path: "/product/:url",
    element: <ProductRenderPage />,
  },
  {
    path: "/product",
    element: <Product />,
  },
  {
    path: "/product/master",
    element: <MasterProduct />,
  },
  {
    path: "/product/master/create",
    element: <CreateMaster />,
  },
  {
    path: "/product/master/edit/:seoURL/:id",
    element: <CreateMaster />,
  },
  {
    path: "/product/details",
    element: <DetailsProduct />,
  },
  {
    path: "/product/details/create",
    element: <CreateDetails />,
  },
  {
    path: "/product/details/edit/:id",
    element: <CreateDetails />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/blog/create",
    element: <CreateBlog />,
  },
  {
    path: "/blog/edit/:seoURL/:id",
    element: <CreateBlog />,
  },
  {
    path: "/faq/create",
    element: <CreateFAQs />,
  },
  {
    path: "/faq/edit/:id",
    element: <EditFaq />,
  },
  {
    path: "/faq/list",
    element: <FAQHeadings />,
  },
  {
    path: "/heading/create",
    element: <CreateHeading />,
  },
  {
    path: "/heading/list",
    element: <Headings />,
  },
  {
    path: "/mange-contact-us",
    element: <ManageContactUs />,
  },
  {
    path: "/mange-contact-us/create",
    element: <MangeContactForm />,
  },
  {
    path: "/mange-contact-us/edit/:id",
    element: <MangeContactForm />,
  },

  {
    path: "/case-study",
    element: <CaseStudy />,
  },
  {
    path: "/case-study/create",
    element: <AddCaseStudy />,
  },
  {
    path: "/case-study/edit/:id",
    element: <AddCaseStudy />,
  },
  {
    path: "/banner",
    element: <Banner />,
  },
  {
    path: "/banner/create",
    element: <AddBanner />,
  },
  {
    path: "/banner/edit/:id",
    element: <AddBanner />,
  },

  {
    path: "/sites",
    element: <Sites />,
  },
  {
    path: "/sites/create",
    element: <CreateSite />,
  },
  {
    path: "/sites/edit/:id",
    element: <CreateSite />,
  },
];

const RouteComponent = () => {

  return (
    <>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            {routeLinks.map((route) => (
              <Route exact path={route.path} element={route.element} />
            ))}
          </Route>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </>
  );
};

export default RouteComponent;
