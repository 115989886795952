import axios from "axios";

export const createFAQ = async (payload) => {
  try {
    const res = await axios.post(`create/faq`, payload);
    return res;
  } catch (error) {
    return error;
  }
};
export const fetchAllFAQs = async () => {
  try {
    const res = await axios.get(`list/faq`);
    return res;
  } catch (error) {
    return error;
  }
};


export const fetchSingleFAQs = async (id) => {
  try {
    const res = await axios.get(`list/faq/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};



export const updateFaq = async (id, payload) => {
  try {
    const res = await axios.put(`update/faq/${id}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};



export const deleteFaq = async (id) => {
  try {
    const res = await axios.delete(`delete/faq/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};