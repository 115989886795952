import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/styles/app.scss";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (reqConfig) => {
    const reqConfiglocal = reqConfig;
    const token = localStorage.getItem("token");

    if (token) {
      reqConfiglocal.headers.Authorization = `${token}`;
    }
    return reqConfiglocal;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // localStorage.clear();
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
