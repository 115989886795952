import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteBlog, getAllBlog } from "../../services/BlogService";
import moment from "moment";

const Blog = () => {
  const navigate = useNavigate();
  const [pageContenList, setPageContentList] = useState([]);

  const getBlogData = async () => {
    const res = await getAllBlog();
    const resData = res.data;
    setPageContentList(resData.data);
  };
  useEffect(() => {
    getBlogData();
  }, []);

  const deletePage = async (id) => {
    const res = await deleteBlog(id);
    if (res) {
      getBlogData();
    }
  };

  return (
    <>
      <Box>
        <Box
          boxShadow="base"
          p={5}
          background="#fff"
          display="flex"
          justifyContent="space-between"
          alignContent="center"
        >
          <Text fontSize="22px">Blogs - List</Text>
          <Button
            onClick={() => navigate("/blog/create")}
            leftIcon={<AddIcon />}
            colorScheme="blue"
            variant="solid"
          >
            Add New Blog
          </Button>
        </Box>
        <Box p={5}>
          <TableContainer background="#fff" boxShadow="base" p={5}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th> Sr. No. </Th>
                  <Th> Image </Th>
                  <Th> Header </Th>
                  <Th> Title </Th>
                  <Th> Author </Th>
                  <Th> Posted On </Th>
                  <Th textAlign={"right"}> Action </Th>
                </Tr>
              </Thead>
              <Tbody>
                {pageContenList.map((page, index) => (
                  <Tr>
                    <Td>{index + 1}</Td>
                    <Td>
                      <Image height="65px" src={page.image} />
                    </Td>
                    <Td>
                      <a target="_blank" href={`/blog/${page.seoURL}`} rel="noreferrer">
                        {page.name}
                      </a>
                    </Td>
                    <Td>{page.title}</Td>
                    <Td>{page.subTitle}</Td>
                    <Td>{moment(page.createdAt).format("DD-MM-YYYY")}</Td>
                    <Td>
                      <HStack justifyContent={"flex-end"}>
                        <IconButton
                          aria-label="Search database"
                          onClick={() =>
                            navigate(`/blog/edit/${page.seoURL}/${page.id}`)
                          }
                          icon={<EditIcon />}
                        />
                        <IconButton
                          aria-label="Search database"
                          onClick={() => deletePage(page.id)}
                          icon={<DeleteIcon />}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default Blog;
