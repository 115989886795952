
import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState, Suspense, lazy } from "react";
import InputControl from "../../components/Input";

import { useNavigate, useParams } from "react-router-dom";
import { fetchSingleFAQs, updateFaq } from "../../services/FaqService";

const Editor = lazy(() => import("../../components/Editor"));


const EditFaq = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [faqList, setFaqList] = useState(
    {
      techsupport: false,
      pricing: false,
      questions: "",
      answer: "",
    },
  );

  const onSubmit = async () => {
    const res = await updateFaq(id, faqList);
    console.log(res, "resData");
    if (res.status === 200) {
      toast({
        title: "FAQ Updated.",
        description: res.data.message || "somthing went wrong",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/faq/list");
    }
    else {
      toast({
        title: "FAQ Updated Failed.",
        description: res.data.message || "somthing went wrong",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    fetchSingleFaq()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchSingleFaq = async () => {
    const res = await fetchSingleFAQs(id);
    setFaqList(res.data.data || []);
  };

  const handleChange = (e, res) => {
    const { name, checked, value, type } = e.target;
    if (res === "answer") {
      setFaqList((prev) => ({ ...prev, [res]: e }));
    }
    if (type === "checkbox") {
      setFaqList((prev) => ({ ...prev, [name]: checked }));
    }
    else {
      setFaqList((prev) => ({ ...prev, [name]: value }));
    }
  }


  return (
    <>
      <Box
        boxShadow="base"
        p={5}
        background="#fff"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Text fontSize="22px">FAQs - Edit</Text>
      </Box>
      <Box p={5} boxShadow="base" background="#fff" m={5}>
        <Box mb={5} mt={5} pos="relative">
          <Stack spacing={5} direction="row">
            <input
              type="checkbox"
              id="techsupport"
              checked={faqList?.techsupport}
              onChange={(e) => handleChange(e)}
              name="techsupport"
            />
            <label htmlFor="techsupport">Tech Support FAQs{" "}</label>
            <input
              type="checkbox"
              id="pricing"
              checked={faqList?.pricing}
              onChange={(e) => handleChange(e)}
              name="pricing"
            />
            <label htmlFor="pricing">Pricing FAQs</label>
          </Stack>
        </Box>
        <Box>
          <InputControl
            label="Qestion"
            placeholder="Qestion"
            name="questions"
            onChange={(e) => handleChange(e)}
            value={faqList?.questions}
          />
        </Box>

        <Box mt={5} mb={5}>
          <FormLabel>Answer</FormLabel>
          <Suspense fallback={<div>Loading...</div>}>
            <Editor
              // onChange={(content, delta, source, editor) => {
              //   setFaqList((prev) => {
              //     const newFaqList = [...prev];
              //     newFaqList.answer = content;
              //     return newFaqList;
              //   });
              // }}
              onChange={(content, delta, source, editor) => handleChange(content, 'answer')}
              value={faqList.answer}
              id={`toolbar___${0}`}
            />
          </Suspense>
        </Box>


        <Flex justify="flex-end" mt={5}>
          <HStack>
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={() => {
                onSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={() => navigate("/faq/list")}
            >
              Cancel
            </Button>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default EditFaq;
