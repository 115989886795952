/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Drawer,
  DrawerContent,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Navigation from "../components/header";

const PrivateRoutes = () => {
  let auth = { token: localStorage.getItem("token") };
  const { isOpen, onOpen, onClose } = useDisclosure();

  return auth.token ? (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <Sidebar
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Navigation onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }}>
        <Outlet />
      </Box>
    </Box>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
