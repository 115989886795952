import { ChakraProvider } from "@chakra-ui/react";
import RouteComponent from "./routes";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const helmetContext = {};

  return (
    <>
      <ChakraProvider>
        <HelmetProvider context={helmetContext}>
          <RouteComponent />
        </HelmetProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
