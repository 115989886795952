import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Select,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState, Suspense, lazy } from "react";
import InputControl from "../../components/Input";

import { useNavigate, useParams } from "react-router-dom";
import {
  createDetailProduct,
  getAllProject,
} from "../../services/ProjectService";

const Editor = lazy(() => import("../../components/Editor"));

const CreateHeading = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [pageData, setPageData] = useState({});
  const [productData, setProductData] = useState([]);
  const [productTerms, setProductTerms] = useState([
    {
      detailsImage: "",
      homeBannerImage: "",
    },
  ]);

  const onSubmit = async () => {
    const { masterProduct, subHeader, productContentDiscription } = pageData;
    const payload = {
      masterProduct,
      contentImage: "contentImage",
      subHeader,
      productContentDiscription,
      productTerms: productTerms,
    };
    const res = await createDetailProduct(payload);
    if (res.status === 200) {
      toast({
        title: "Product Details created.",
        description: res?.response?.data?.message || "something went wrong",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/product/details");
    }
    else {
      toast({
        title: "Error.",
        description: "Something went wrong!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }


  };
  useEffect(() => {
    const productDetailsData = localStorage.getItem("productDetailsData");

    if (id) {
      if (productDetailsData) {
        const localPageDataObj = JSON.parse(productDetailsData);
        const pageData = localPageDataObj.find((page) => page.seoURL === id);

        setPageData(pageData);
      }
    }
  }, [id]);

  useEffect(() => {
    getAllMasterProduct();
  }, []);

  const getAllMasterProduct = async () => {
    const res = await getAllProject();
    console.log(res, "resDAta");
    const resData = res.data;
    if (resData.status) {
      setProductData(resData.data);
    }
  };

  const onEditPageHandler = () => {
    const localPageData = localStorage.getItem("productDetailsData");
    if (localPageData) {
      const localPageDataObj = JSON.parse(localPageData);
      const newPageData = localPageDataObj.filter((page) => page.seoURL !== id);
      newPageData.push(pageData);
      localStorage.setItem("productDetailsData", JSON.stringify(newPageData));
    }
    toast({
      title: "Product Details updated.",
      status: "success",
      duration: 9000,
    });
    navigate("/product/details");
  };

  const addProductTerms = () => {
    setProductTerms([
      ...productTerms,
      {
        heading: "",
        subHeading: "",
        detailsImage: "",
        homeBannerImage: "",
        homeDescription: "",
        productDetailDescription: "",
      },
    ]);
  };

  const deleteProductTerms = (index) => {
    const newProductTerms = productTerms.filter((term, i) => i !== index);
    setProductTerms(newProductTerms);
  };

  return (
    <>
      <Box
        boxShadow="base"
        p={5}
        background="#fff"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Text fontSize="22px">Product Details - Create</Text>
      </Box>
      <Box p={5} boxShadow="base" background="#fff" m={5}>
        <Heading fontSize="2x2" mb={3}>
          Create Products Details
        </Heading>
        <SimpleGrid columns={3} spacing={5}>
          <Box>
            <FormLabel>Products</FormLabel>
            <Select
              placeholder="Select Product"
              onChange={(e) => {
                setPageData((prev) => ({
                  ...prev,
                  masterProduct: e.target.value,
                }));
              }}
            >
              {productData.map((product) => (
                <option value={product.id}>{product.title}</option>
              ))}
            </Select>
          </Box>
          <Box>
            <InputControl
              label="Content Image"
              placeholder="Image"
              type="file"
              onChange={(e) =>
                setPageData((prev) => ({
                  ...prev,
                  contentImage: e.target.files[0],
                }))
              }
              value={pageData?.contentImage}
            />
          </Box>
          <Box>
            <InputControl
              label="Sub Header"
              placeholder="Enter Sub Header"
              onChange={(e) =>
                setPageData((prev) => ({ ...prev, subHeader: e.target.value }))
              }
              value={pageData.subHeader}
            />
          </Box>
        </SimpleGrid>

        <Box mt={5} mb={5}>
          <FormLabel>Description</FormLabel>
          <Suspense fallback={<div>Loading...</div>}>
            <Editor
              onChange={(content, delta, source, editor) => {
                setPageData((prev) => ({
                  ...prev,
                  productContentDiscription: editor.getHTML(),
                }));
              }}
              value={pageData?.productContentDiscription}
              id="toolbar"
            />
          </Suspense>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Heading fontSize="2x1">PRODUCT TERMS</Heading>
          <IconButton
            onClick={addProductTerms}
            aria-label="Search database"
            icon={<AddIcon />}
          />
        </Box>
        <Box>
          {productTerms.map((productTerm, index) => (
            <Box
              p={5}
              boxShadow="base"
              background="#fff"
              m={5}
              position="relative"
            >
              <IconButton
                position="absolute"
                right="5px"
                top="5px"
                onClick={() => deleteProductTerms(index)}
                aria-label="Search database"
                icon={<DeleteIcon />}
              />
              <SimpleGrid columns={2} spacing={5}>
                <Box>
                  <InputControl
                    label="Heading"
                    placeholder="Enter Heading"
                    onChange={(e) => {
                      const newProductTerms = [...productTerms];
                      newProductTerms[index].heading = e.target.value;
                      setProductTerms(newProductTerms);
                    }}
                    value={productTerm.heading}
                  />
                </Box>
                <Box>
                  <InputControl
                    label="Sub Heading"
                    placeholder="Enter Sub Heading"
                    onChange={(e) => {
                      const newProductTerms = [...productTerms];
                      newProductTerms[index].subHeading = e.target.value;
                      setProductTerms(newProductTerms);
                    }}
                    value={productTerm.subHeading}
                  />
                </Box>
                <Box>
                  <InputControl
                    label="Detail Image"
                    type="file"
                    onChange={(e) => {
                      const newProductTerms = [...productTerms];
                      newProductTerms[index].detailsImage = "detailsImage";
                      setProductTerms(newProductTerms);
                    }}
                    value={productTerm.detailsImage}
                  />
                </Box>
                <Box>
                  <InputControl
                    label="Home Banner Image"
                    type="file"
                    onChange={(e) => {
                      const newProductTerms = [...productTerms];
                      newProductTerms[index].homeBannerImage =
                        "homeBannerImage";
                      setProductTerms(newProductTerms);
                    }}
                    value={productTerm.homeBannerImage}
                  />
                </Box>
              </SimpleGrid>
              <Box mt={5} mb={5}>
                <FormLabel>Home Description</FormLabel>
                <Suspense fallback={<div>Loading...</div>}>
                  <Editor
                    onChange={(content, delta, source, editor) => {
                      const newProductTerms = [...productTerms];
                      newProductTerms[index].homeDescription = editor.getHTML();
                      setProductTerms(newProductTerms);
                    }}
                    value={productTerm?.homeDescription}
                    id={`toolbar_${index}`}
                  />
                </Suspense>
              </Box>
              <Box mt={5} mb={5}>
                <FormLabel>Prpduct Details Description</FormLabel>
                <Suspense fallback={<div>Loading...</div>}>
                  <Editor
                    onChange={(content, delta, source, editor) => {
                      const newProductTerms = [...productTerms];
                      newProductTerms[index].productDetailDescription =
                        editor.getHTML();
                      setProductTerms(newProductTerms);
                    }}
                    value={productTerm?.productDetailDescription}
                    id={`toolbar__${index}`}
                  />
                </Suspense>
              </Box>
            </Box>
          ))}
        </Box>

        <Flex justify="flex-end" mt={5}>
          <HStack>
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={() => {
                if (id) {
                  onEditPageHandler();
                } else {
                  onSubmit();
                }
              }}
            >
              Submit
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={() => navigate("/page/add")}
            >
              Cancel
            </Button>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};

export default CreateHeading;
